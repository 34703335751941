import React, { useContext } from 'react';
import { CircularProgress } from '@material-ui/core';
import { AppStateContext } from '../contexts/AppStateContext';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const [appState] = useContext(AppStateContext);
  const { isLoading } = appState;
  const { t } = useTranslation('maastotaulukot');

  return (
    <div className={`loading ${isLoading ? 'is-active' : ''}`}>
      <span className="loading-icon">
        <CircularProgress />
      </span>
      <p className="loading-text">{t("app.loading")}</p>
    </div>
  );
};

export default Loading;
