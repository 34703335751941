import React, { useContext, useCallback, useEffect, useState } from 'react';
import './sass/styles.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Page from './pages/Page';
import Header from './components/navigation/Header';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box, CssBaseline } from '@material-ui/core';
import Favorites from './pages/Favorites';
import User from './pages/User';
import Search from './pages/Search';
import Activate from './pages/Activate';
import Register from './pages/Register';
import LogIn from './pages/LogIn';
import AskResetPasswordLink from './pages/AskResetPasswordLink';
import Verify from './pages/Verify';
import LicenseChecker from './components/api/LicenseChecker';
import TokenRefresher from './components/api/TokenRefresher';
import UserRefresher from './components/api/UserRefresher';
import { AppStateContext } from './components/contexts/AppStateContext';
import Loading from './components/utils/Loading';
import FavoritesChecker from './components/api/FavoritesChecker';
import Toast from './components/utils/Toast';
import Preview from './pages/Preview';
import DocsChecker from './components/api/DocsChecker';
import ScrollToTop from './components/utils/ScrollToTop';
import ResetPassword from './pages/ResetPassword';
import useWindowSize from './utils/useWindowSize';
import { useServiceWorker } from './components/contexts/ServiceWorkerContext';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { analyticsInit, defaultConsent } from './analytics/analyticsInit';

const App = (props) => {
  const { online } = props;
  const [appState] = useContext(AppStateContext);
  const [, height] = useWindowSize();
  const { t } = useTranslation('maastotaulukot');
  const [cookieConsentAccept, setCookieConsentAccept] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const showContent = appState.isValidLicense === true;

  // Update Service Worker on launch
  const serviceWorker = useServiceWorker();
  const getRegistration = useCallback(async () => {
    if ('serviceWorker' in navigator) {
      const promise = Promise.resolve(await navigator.serviceWorker.getRegistration());
      promise
        .then((result) => {
          console.log('Service Worker registration: ', result);
          if (result && result.waiting) {
            serviceWorker.updateAssets();
          }
        })
        .catch((err) => console.log(err));
    }
  }, [serviceWorker]);

  useEffect(() => {
    if (serviceWorker) {
      getRegistration();
    }
  }, [getRegistration, serviceWorker]);

  // Cookie consent
  useEffect(() => {
    const declined = window.localStorage.getItem('cookieConsentDeclined');
    if (getCookieConsentValue('CookieConsent') === 'true') {
      console.log('cookies accepted');
      setShowCookieConsent(false);
      analyticsInit();
    } else if (!declined) {
      console.log('cookies not accepted or declined');
      setShowCookieConsent(true);
      defaultConsent();
    }
    if (declined) {
      console.log('cookies declined');
      setShowCookieConsent(false);
    }
  }, [cookieConsentAccept]);

  const theme = createTheme({});

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ScrollToTop />
        <UserRefresher online={online} />
        <TokenRefresher online={online} />
        <LicenseChecker online={online} />
        <FavoritesChecker online={online} />
        <DocsChecker online={online} />
        <Box className="app" style={{ height: height - 56 }}>
          <Header online={online} />
          {showCookieConsent && (
            <CookieConsent
              className="cookie-consent"
              location="bottom"
              buttonText={t('cookie_consent.accept')}
              enableDeclineButton
              declineButtonText={t('cookie_consent.decline')}
              ariaAcceptLabel={t('cookie_consent.accept')}
              ariaDeclineLabel={t('cookie_consent.decline')}
              cookieName="CookieConsent"
              expires={365}
              disableStyles
              onAccept={() => setCookieConsentAccept(true)}
              onDecline={() => window.localStorage.setItem('cookieConsentDeclined', true)}
              setDeclineCookie={false}
              buttonClasses="button default small"
              declineButtonClasses="button transparent small"
            >
              <p>
                {t('cookie_consent.message')}
                <Link aria-label={t('cookie_consent.read_more')} to="/privacy-policy">
                  {t('cookie_consent.read_more')}
                </Link>
              </p>
            </CookieConsent>
          )}
          <Switch>
            <Route path="/preview" exact component={Preview} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            {appState.isLoggedIn
              ? [
                  <Route
                    key="/activate"
                    path="/activate"
                    exact
                    render={(props) => {
                      return <Activate {...props} online={online} />;
                    }}
                  />,
                  !showContent
                    ? [
                        <Route
                          key="/user"
                          path="/user"
                          exact
                          render={(props) => {
                            return <User {...props} online={online} />;
                          }}
                        />,
                        <Route key="/about" path="/about" exact component={Page} />,
                        <Route
                          key="/"
                          path="/"
                          render={(props) => {
                            return <Activate {...props} online={online} />;
                          }}
                        />,
                      ]
                    : [
                        <Route key="/pages/:id" path="/pages/:id" exact component={Page} />,
                        <Route key="/" path="/" exact component={Page} />,
                        <Route key="/about" path="/about" exact component={Page} />,
                        <Route
                          key="/user"
                          path="/user"
                          exact
                          render={(props) => {
                            return <User {...props} online={online} />;
                          }}
                        />,
                        <Route
                          key="/favorites"
                          path="/favorites"
                          exact
                          render={(props) => {
                            return <Favorites {...props} online={online} />;
                          }}
                        />,
                        <Route path="/search" exact component={Search} />,
                      ],
                ]
              : [
                  <Route
                    key="/register"
                    path="/register"
                    exact
                    render={(props) => {
                      return <Register {...props} online={online} />;
                    }}
                  />,
                  <Route
                    key="/verify/:key"
                    path="/verify/:key"
                    exact
                    render={(props) => {
                      return <Verify {...props} online={online} />;
                    }}
                  />,
                  <Route
                    key="/ask-reset"
                    path="/ask-reset"
                    exact
                    render={(props) => {
                      return <AskResetPasswordLink {...props} online={online} />;
                    }}
                  />,
                  <Route
                    key="/reset-password/:key"
                    path="/reset-password/:key"
                    exact
                    render={(props) => {
                      return <ResetPassword {...props} online={online} />;
                    }}
                  />,
                  <Route
                    key="1"
                    render={(props) => {
                      return <LogIn {...props} online={online} />;
                    }}
                  />,
                ]}
          </Switch>
          <Loading />
          <Toast />
        </Box>
      </ThemeProvider>
    </Router>
  );
};

export default App;
