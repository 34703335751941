import React, { useState, useEffect } from 'react';
import CounterInput from './CounterInput';
import CounterSlider from './CounterSlider';
import { Tabs, Tab } from '@material-ui/core';
import pine from '../../data/relaskooppi_mannikko.json';
import spruce from '../../data/relaskooppi_kuusikko.json';
import birch from '../../data/relaskooppi_koivikko.json';
import FromBaseAreaToTrunkCount from './FromBaseAreaToTrunkCount';

const tables = {
  0: pine,
  1: spruce,
  2: birch,
};

const RelascopeTables = () => {
  const [tab, setTab] = useState(0);

  const [m, setM] = useState(18); // Männikkö default
  const [m2Ha, setM2Ha] = useState(22); // Männikkö default
  const [cm, setCm] = useState(20);

  const [trunkVolume, setTrunkVolume] = useState(0);

  const [logPercent, setLogPercent] = useState(0);
  const [logVolume, setLogVolume] = useState(0);
  const [fiberVolume, setFiberVolume] = useState(0);

  useEffect(() => {
    let trunkVol;
    if (tab === 0) {
      trunkVol = (0.4116 - 0.04275 * Math.pow(m, 1.5) + 0.6359 * m) * m2Ha;
    } else if (tab === 1) {
      trunkVol = (1.3187 + 0.00099 * Math.pow(m, 2) + 0.3978 * m) * m2Ha;
    } else if (tab === 2) {
      trunkVol = (0.4907 - 0.00137 * Math.pow(m, 2) + 0.4556 * m) * m2Ha;
    }

    const logPerc = m >= 12 ? tables[tab][m - 12][cm - 13] : null;
    const logVol = trunkVol * (logPerc / 100);

    const leftOverPercent = Math.exp(
      28.07 -
        12.93 * Math.log(cm) +
        1.415 * Math.pow(Math.log(cm), 2) -
        9.026 * Math.pow(1.4 / Math.log(cm), 2) +
        0.146 * Math.log(35 - m)
    );
    const leftOverAmount = (trunkVol * leftOverPercent) / 100;
    const fiberVol = parseInt((trunkVol - logVol - leftOverAmount).toFixed(0));

    setTrunkVolume(trunkVol);
    setLogVolume(logVol);
    setLogPercent(logPerc);
    setFiberVolume(fiberVol);
  }, [m, m2Ha, cm, setTrunkVolume, tab]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    // Männikko
    if (tab === 0) {
      setM(18);
      setM2Ha(22);
    }
    // Kuusikko
    else if (tab === 1) {
      setM(17);
      setM2Ha(21);
    }
    // Koivikko
    else if (tab === 2) {
      setM(16);
      setM2Ha(20);
    }
  }, [tab, setM, setM2Ha]);

  return (
    <div className="slice counter">
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Männikkö" />
        <Tab label="Kuusikko" />
        <Tab label="Koivikko" />
      </Tabs>

      <CounterSlider
        defaultValue={0}
        label="Keskipituus (m)"
        min={6}
        max={30}
        value={m}
        onChange={(e, newValue) => setM(newValue)}
      />

      <CounterSlider
        defaultValue={0}
        label="Pohjapinta-ala (m²/ha)"
        min={5}
        max={40}
        value={m2Ha}
        onChange={(e, newValue) => setM2Ha(newValue)}
      />

      <CounterSlider
        defaultValue={0}
        label="Keskiläpimitta (cm) *pohjapinta-alalla painotettu"
        min={13}
        max={39}
        value={cm}
        onChange={(e, newValue) => setCm(newValue)}
      />

      {logPercent === 0 || logVolume === 0 ? <p className="red">Puutavaralajien osuuksia ei voida laskea näillä puustotiedoilla!</p> : ""}

      <div className="form-row result">
        <CounterInput
          type="number"
          label="Runkotilavuus kuorineen (m³/ha)"
          value={trunkVolume}
          placeholder="0"
          result
        />
        <FromBaseAreaToTrunkCount m2Ha={m2Ha} cm={cm} species={tab} />
      </div>

      <div className="form-row result">
        <CounterInput
          type="number"
          label="Tukkipuuosuus (%)"
          value={logPercent}
          placeholder="0"
          result
        />
        <CounterInput
          type="number"
          label="Tukkipuuta (m³)"
          value={logVolume}
          placeholder="0"
          result
        />
        <CounterInput
          type="number"
          label="Kuitupuuta (m³)"
          value={fiberVolume}
          placeholder="0"
          result
        />
      </div>

      <p className="small">
        Puutavaralajien määrät perustuvat Nyyssösen ja Ojansuun (1982) laatimiin metsikkökohtaisiin
        malleihin (Acta Forestalia Fennica 179), joissa tukkipuun minimiläpimitta on 17 cm ja
        kuitupuun 7 cm.
      </p>
    </div>
  );
};

export default RelascopeTables;
