import React, { useState, createContext } from 'react';

export const LicensesContext = createContext();

export const LicensesProvider = (props) => {
  const [licenses, setLicenses] = useState([]);
  return (
    <LicensesContext.Provider value={[licenses, setLicenses]}>
      {props.children}
    </LicensesContext.Provider>
  );
};
