import React, { useState, useContext } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { ExitToApp, StarOutline, HelpOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { IconButton, MenuItem, Menu, ListItemIcon } from '@material-ui/core';
import { withRouter } from 'react-router';
import { UserContext } from '../contexts/UserContext';
import { ReactComponent as Logo } from '../../assets/logos/logo-2.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/icon-menu.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/icon-profile.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/icon-magnifier-white.svg';
import { ReactComponent as FavoritesIcon } from '../../assets/icons/icon-favourites.svg';
import { ReactComponent as FavoritesActiveIcon } from '../../assets/icons/icon-favourites-active.svg';
import { useTranslation } from 'react-i18next';
import apiEndpoint from '../../utils/apiEndpoint';
import axios from 'axios';
import { AppStateContext } from '../contexts/AppStateContext';
import { ReactComponent as CloudIcon } from '../../assets/icons/icon-cloud.svg';
import { FavoritesContext } from '../contexts/FavoritesContext';

const Header = (props) => {
  const { location, history, online } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [appState, setAppState] = useContext(AppStateContext);
  const [user] = useContext(UserContext);
  const { t } = useTranslation('maastotaulukot');
  const [favorites, setFavorites] = useContext(FavoritesContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleFavorites = async (key, id) => {
    const index = favorites?.findIndex((x) => x.key === key);
    if (index === -1) {
      await axios
        .post(`${apiEndpoint}/api/favorites/`, { key: key })
        .then(async (response) => {
          console.log('favorites add response: ', response.data);
          if (response.data) {
            setAppState({ ...appState, successMessage: 'added_to_favorites' });
            await axios
              .get(`${apiEndpoint}/api/favorites/`)
              .then((response) => {
                console.log('favorites refresh response: ', response.data);
                if (response.data) {
                  setFavorites(response.data);
                  window.localStorage.setItem('favorites', JSON.stringify(response.data));
                }
              })
              .catch((err) => {
                setAppState({ ...appState, errorMessage: 'unhandled_error' });
                console.log('favorites refresh error: ', err);
              });
          }
        })
        .catch((err) => console.log('favorites toggle error: ', err.response));
    } else if (index > -1) {
      await axios
        .delete(`${apiEndpoint}/api/favorites/${id}/`)
        .then(async (response) => {
          console.log('favorites delete response: ', response.data);
          if (response.status === 204) {
            setAppState({ ...appState, successMessage: 'deleted_from_favorites' });

            await axios
              .get(`${apiEndpoint}/api/favorites/`)
              .then((response) => {
                console.log('favorites delete fetch response: ', response.data);
                if (response.data) {
                  setFavorites(response.data);
                  window.localStorage.setItem('favorites', JSON.stringify(response.data));
                }
              })
              .catch((err) => {
                setAppState({ ...appState, errorMessage: 'unhandled_error' });
                console.log('favorites refresh error: ', err.response);
              });
          }
        })
        .catch((err) => {
          setAppState({ ...appState, errorMessage: 'unhandled_error' });
          console.log('favorites delete error: ', err.response);
        });
    }
  };

  const logOut = async () => {
    handleClose();
    if (online === true) {
      await axios
        .post(`${apiEndpoint}/api/auth/logout/`)
        .then((response) => {
          console.log('log out response: ', response);
        })
        .catch((err) => {
          console.log('log out error response: ', err.response);
        });
    }

    console.log('logging out, cleaning storage...');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('refresh');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('license');
    window.localStorage.removeItem('favorites');
    window.location = '/';
  };

  return (
    <AppBar position="sticky" className="header">
      <div className={`network-notice ${online === false ? 'is-active' : ''}`} aria-hidden="true">
        <CloudIcon />
        <p>{t('header.network_notice')}</p>
      </div>
      <Toolbar>
        <div className="header-left">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="header-right">
          {appState.isLoggedIn && (
            <>
              {location.pathname.includes('pages') && !location.pathname.includes('search') && (
                <IconButton
                  className={online === true ? '' : 'disabled'}
                  onClick={() => {
                    if (online === true) {
                      const index = favorites.findIndex(
                        (x) => x.key === location?.pathname?.split('/')[2]
                      );
                      if (index > -1) {
                        const favorite = favorites[index];
                        toggleFavorites(favorite.key, favorite.id);
                      } else {
                        toggleFavorites(location?.pathname?.split('/')[2]);
                      }
                    } else {
                      setAppState({ ...appState, errorMessage: 'no_network_connection' });
                    }
                  }}
                >
                  {favorites?.findIndex((x) => x.key === location?.pathname?.split('/')[2]) ===
                  -1 ? (
                    <FavoritesIcon />
                  ) : (
                    <FavoritesActiveIcon />
                  )}
                </IconButton>
              )}
              <IconButton
                onClick={() => {
                  history.push('/search');
                }}
              >
                <SearchIcon />
              </IconButton>
              {user.username && (
                <IconButton
                  onClick={() => {
                    history.push('/user');
                  }}
                >
                  <UserIcon />
                </IconButton>
              )}
              <IconButton onClick={handleMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                className="menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push('/favorites');
                  }}
                >
                  <ListItemIcon>
                    <StarOutline fontSize="small" />
                  </ListItemIcon>
                  {t('header.favorites')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    logOut();
                  }}
                >
                  <ListItemIcon>
                    <ExitToApp fontSize="small" />
                  </ListItemIcon>
                  {t('header.log_out')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push('/about');
                  }}
                >
                  <ListItemIcon>
                    <HelpOutline fontSize="small" />
                  </ListItemIcon>
                  {t('header.about')}
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Header);
