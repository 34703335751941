import apiEndpoint from './apiEndpoint';
import axios from 'axios';

const verifyPassword = (pw) => {
  const response = axios({
    method: 'post',
    url: `${apiEndpoint}/api/account/password/verify/`,
    data: {
      password: pw,
    },
  });
  return response;
};

export default verifyPassword;
