import React, { useEffect, useState, useContext } from 'react';
import { AppStateContext } from '../contexts/AppStateContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIconWhite } from '../../assets/icons/icon-close-white.svg';
import { IconButton } from '@material-ui/core';

const Toast = () => {
  const [appState, setAppState] = useContext(AppStateContext);
  const { t } = useTranslation('maastotaulukot');
  const [active, setActive] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [appState, setAppState]);

  useEffect(() => {
    if (!active) {
      const timer = setTimeout(() => {
        setAppState((state) => {
          return { ...state, errorMessage: '', successMessage: '' };
        });
      }, 150);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [active, setAppState]);

  useEffect(() => {
    if (appState.errorMessage !== '') {
      setActive(true);
      setAppState((state) => {
        return { ...state, successMessage: '' };
      });
    }
  }, [appState.errorMessage, setAppState]);

  useEffect(() => {
    if (appState.successMessage !== '') {
      setActive(true);
      setAppState((state) => {
        return { ...state, errorMessage: '' };
      });
    }
  }, [appState.successMessage, setAppState]);

  return (
    <div className={`toast ${active ? 'is-active' : ''}`}>
      <div className={`wrap ${appState.errorMessage !== '' ? 'red' : 'green'}`}>
        <div className="content">
          <p>
            {t(
              `toast.${
                appState.errorMessage !== '' ? appState.errorMessage : appState.successMessage
              }`
            )}
          </p>
        </div>
        <div className="close">
          <span>
            <IconButton onClick={() => setActive(false)}>
              <CloseIconWhite />
            </IconButton>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Toast;
