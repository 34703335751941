import React, { useState, useEffect, useCallback } from 'react';
import { Paper } from '@material-ui/core';
import BackButton from '../components/navigation/BackButton';
import { useTranslation } from 'react-i18next';
import apiEndpoint from '../utils/apiEndpoint';
import axios from 'axios';
import { ReactComponent as AlertIcon } from '../assets/icons/icon-alert.svg';
import { ReactComponent as CheckmarkIcon } from '../assets/icons/icon-checkmark.svg';

const Verify = (props) => {
  const { match } = props;
  const { t } = useTranslation('maastotaulukot');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [verifyData, setVerifyData] = useState({});

  useEffect(() => {
    document.title = `${t('verify.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  // Get verify status
  const getVerifyStatus = useCallback(async () => {
    await axios
      .get(`${apiEndpoint}/api/account/confirm-email/${match.params.key}/`)
      .then((response) => {
        console.log(response.data);
        if (response.data.detail === 'ok') {
          setSuccess(true);
        } else  {
          setVerifyData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, [match]);

  useEffect(() => {
    getVerifyStatus();
  }, [getVerifyStatus]);

  // Verify email
  const verifyEmail = async () => {
    await axios
      .post(`${apiEndpoint}/api/account/confirm-email/${match.params.key}/`, verifyData)
      .then((response) => {
        console.log(response.data);
        if (response.data.detail === 'ok') {
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <Paper className="login-box">
          <BackButton noWrap parentLink="/" parentTitle={t('login.title')} />
          {!success && !error && (
            <>
              <div className="title-wrap">
                <h1>{t('verify.title')}</h1>
                <p>{t('verify.title_message')}</p>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  verifyEmail();
                }}
              >
                <button className="button default full-width" type="submit">
                  {t('verify.title')}
                </button>
              </form>
            </>
          )}
          {success && (
            <div className="title-wrap no-margin">
              <CheckmarkIcon />
              <h1>{t('verify.success_title')}</h1>
              <p>{t('verify.success_message')}</p>
            </div>
          )}
          {error && (
            <>
              <div className="title-wrap">
                <AlertIcon />
                <h1 className="red">{t('verify.error_title')}</h1>
                <p>{t('verify.error_message')}</p>
              </div>
            </>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default Verify;
