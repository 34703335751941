import linkResolver from './linkResolver';
import { Link } from 'react-router-dom';

const customLink = (type, element, content, children, index) => {
  //console.log('customLink:', type, element, content, children, index);

  if (element?.data?.link_type === 'Document') {
    return (
      <Link key={element.data.id} to={linkResolver(element.data)}>
        {content}
      </Link>
    );
  } else {
    return (
      <a key={element?.data?.id} href={element?.data?.url} target={element?.data?.target}>
        {content}
      </a>
    );
  }
};

export default customLink;
