import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close.svg';
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg';
import { ReactComponent as EyeOffIcon } from '../assets/icons/icon-eye-off.svg';
import BackButton from '../components/navigation/BackButton';
import { useTranslation } from 'react-i18next';
import apiEndpoint from '../utils/apiEndpoint';
import verifyPassword from '../utils/verifyPassword';
import axios from 'axios';
import { ReactComponent as CheckmarkIcon } from '../assets/icons/icon-checkmark.svg';
import NoConnection from '../components/utils/NoConnection';
import { AppStateContext } from '../components/contexts/AppStateContext';

const Register = (props) => {
  const { online, match } = props;
  const [pw, setPw] = useState('');
  const [pwRepeat, setPwRepeat] = useState('');
  const [pwVisible, setPwVisible] = useState(false);
  const { t } = useTranslation('maastotaulukot');
  const [success, setSuccess] = useState(false);
  const [appState, setAppState] = useContext(AppStateContext);

  useEffect(() => {
    document.title = `${t('reset_password.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  // Try to reset
  const resetPw = async () => {
    setAppState({ ...appState, isLoading: true });

    await axios
      .post(`${apiEndpoint}/api/account/password/reset/${match.params.key}/`, {
        new_password1: pw,
        new_password2: pw,
      })
      .then((response) => {
        console.log(response.data);
        setSuccess(true);
        setAppState({ ...appState, isLoading: false });
      })
      .catch((err) => {
        console.log(err.response);
        // TODO: fix this response
        if (err.response?.data?.new_password2[0].includes('Salasana on liian lähellä')) {
          setAppState({ ...appState, errorMessage: 'password_too_similar', isLoading: false });
        } else {
          setAppState({ ...appState, errorMessage: 'unhandled_error', isLoading: false });
        }
      });
  };

  // Verify user input
  const verifyPw = async () => {
    if (pw === pwRepeat) {
      await verifyPassword(pw)
        .then((response) => {
          console.log(response.data);
          if (response.data?.accepted === true) {
            resetPw();
            setAppState({ ...appState, isLoading: false });
          } else {
            setAppState({
              ...appState,
              errorMessage: response.data?.warning[0]?.code,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          setAppState({ ...appState, errorMessage: 'unhandled_error', isLoading: false });
        });
    } else {
      setAppState({ ...appState, errorMessage: 'passwords_not_equal', isLoading: false });
    }
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <Paper className="login-box">
          {online === true ? (
            <>
              <BackButton noWrap parentLink="/" parentTitle={t('login.title')} />
              {!success ? (
                <>
                  <div className="title-wrap">
                    <h1>{t('reset_password.title')}</h1>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      verifyPw();
                    }}
                  >
                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type={pwVisible ? 'text' : 'password'}
                        placeholder={t('app.placeholder_password')}
                        value={pw}
                        onChange={(e) => setPw(e.target.value)}
                      />
                      {pw.length > 0 && (
                        <>
                          <span className="eye" onClick={() => setPwVisible(!pwVisible)}>
                            {pwVisible ? <EyeOffIcon /> : <EyeIcon />}
                          </span>
                          <span className="clear" onClick={() => setPw('')}>
                            <CloseIcon />
                          </span>
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type={pwVisible ? 'text' : 'password'}
                        placeholder={t('app.placeholder_repeat_password')}
                        value={pwRepeat}
                        onChange={(e) => setPwRepeat(e.target.value)}
                      />
                      {pwRepeat.length > 0 && (
                        <>
                          <span className="eye" onClick={() => setPwVisible(!pwVisible)}>
                            {pwVisible ? <EyeOffIcon /> : <EyeIcon />}
                          </span>
                          <span className="clear" onClick={() => setPwRepeat('')}>
                            <CloseIcon />
                          </span>
                        </>
                      )}
                    </div>

                    <button className="button default full-width" type="submit">
                      {t('reset_password.title')}
                    </button>
                  </form>
                </>
              ) : (
                <div className="title-wrap no-margin">
                  <CheckmarkIcon />
                  <h1>{t('reset_password.success_title')}</h1>
                  <p>{t('reset_password.success_message')}</p>
                </div>
              )}
            </>
          ) : (
            <NoConnection />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default Register;
