import React, { useContext, useEffect, useState } from 'react';
import { DocsContext } from '../components/contexts/DocsContext';
import Slices from '../components/utils/Slices';
import BackButton from '../components/navigation/BackButton';
import { RichText } from 'prismic-reactjs';
import packageJson from '../../package.json';
import { buildInfo } from '../buildInfo';
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../components/contexts/AppStateContext';

const Page = (props) => {
  const { match, history } = props;
  const [docs] = useContext(DocsContext);
  const [doc, setDoc] = useState({});
  const [notFound, setNotFound] = useState(false);
  const { t } = useTranslation('maastotaulukot');
  const [appState, setAppState] = useContext(AppStateContext);
  const { isLoading } = appState;

  useEffect(() => {
    if (!notFound) {
      document.title = `${
        doc?.data?.title?.length > 0 ? `${doc?.data?.title[0]?.text} - ` : ''
      }TAPIO Maastotaulukot`;
    } else {
      document.title = `${t('app.not_found')} - TAPIO Maastotaulukot`;
    }
  }, [doc, notFound, t]);

  // Select correct doc form list of docs
  useEffect(() => {
    const docCopy = docs.find((item) => {
      if (match.path === '/') {
        return item.doc.type === 'home';
      } else if (!match.path.includes('/pages/')) {
        return item.doc?.type === match.path.replace('/', '').replace('-', '_');
      } else {
        return item.id === match.params.id;
      }
    });

    if (docs.length > 0) {
      if (isLoading) {
        setAppState((state) => {
          return { ...state, isLoading: false };
        });
      }
      if (docCopy) {
        setNotFound(false);
        setDoc(docCopy.doc);
      } else {
        setNotFound(true);
      }
    } else if (docs.length === 0 && !notFound) {
      if (!isLoading) {
        setAppState((state) => {
          return { ...state, isLoading: true };
        });
      }
    } else {
      if (!isLoading) {
        setAppState((state) => {
          return { ...state, isLoading: true };
        });
      }
    }
  }, [docs, match, notFound, isLoading, setAppState]);

  return (
    <div className="page">
      {notFound ? (
        <>
          {match.path !== '/' && <BackButton />}
          <div className="page-title">
            <h1>{t('app.not_found')}</h1>
          </div>
          <div className="slices-container">
            <p>{t('app.not_found_message')}</p>
            {match.path !== '/' && (
              <button onClick={() => history.push('/')} className="button default small">
                {t('app.to_homepage')}
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          {match.path !== '/' && <BackButton doc={doc} />}
          {doc?.data?.title?.length > 0 && doc?.type !== 'home' && (
            <div className="page-title">
              <RichText render={doc?.data?.title} />
            </div>
          )}
          <div className={match.path === '/' ? 'slices-container home' : 'slices-container'}>
            <Slices doc={doc} slices={doc?.data?.body} type={doc?.data?.type} />
            {match.path === '/about' && (
              <div className="build-info">
                <h2>Sovelluksen versio</h2>
                <p>versionumero: {packageJson.version}</p>
                {buildInfo?.buildDate && <p>versiopvm: {buildInfo.buildDate}</p>}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Page;
