import React, { useEffect, useState, useContext } from 'react';
import BackButton from '../components/navigation/BackButton';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close.svg';
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg';
import { ReactComponent as EyeOffIcon } from '../assets/icons/icon-eye-off.svg';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../components/contexts/UserContext';
import { AppStateContext } from '../components/contexts/AppStateContext';
import apiEndpoint from '../utils/apiEndpoint';
import verifyPassword from '../utils/verifyPassword';
import axios from 'axios';
import { getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent';
import { Link } from 'react-router-dom';

const User = (props) => {
  const { history, online } = props;
  const [pw, setPw] = useState('');
  const [pwRepeat, setPwRepeat] = useState('');
  const [pwVisible, setPwVisible] = useState(false);
  const { t } = useTranslation('maastotaulukot');
  const [user] = useContext(UserContext);
  const [appState, setAppState] = useContext(AppStateContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const cookies = getCookieConsentValue() || window.localStorage.getItem('cookieConsentDeclined');

  useEffect(() => {
    document.title = `${t('user.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  useEffect(() => {
    if (user.first_name && user.first_name !== '') {
      setFirstName(user.first_name);
    }
    if (user.last_name && user.last_name !== '') {
      setLastName(user.last_name);
    }
  }, [user]);

  // Reset password after password is verified
  const resetPassword = async () => {
    setAppState({ ...appState, isLoading: true });

    await axios
      .post(`${apiEndpoint}/api/account/password/change/`, {
        new_password1: pw,
        new_password2: pw,
      })
      .then((response) => {
        console.log(response.data);
        setAppState({ ...appState, isLoading: false, successMessage: 'password_changed' });
      })
      .catch((err) => {
        console.log(err.response);
        // TODO: fix this response
        if (err.response?.data?.new_password2[0].includes('Salasana on liian lähellä')) {
          setAppState({ ...appState, errorMessage: 'password_too_similar', isLoading: false });
        } else {
          setAppState({ ...appState, errorMessage: 'unhandled_error', isLoading: false });
        }
      });
  };

  // Verify user password input first
  const verifyUserPassword = async () => {
    setAppState({ ...appState, isLoading: true });

    if (pw === pwRepeat) {
      await verifyPassword(pw)
        .then((response) => {
          console.log(response.data);
          if (response.data?.accepted === true) {
            resetPassword();
            setAppState({ ...appState, isLoading: false });
          } else {
            setAppState({
              ...appState,
              errorMessage: response.data?.warning[0]?.code,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          setAppState({ ...appState, errorMessage: 'unhandled_error', isLoading: false });
        });
    } else {
      setAppState({ ...appState, errorMessage: 'passwords_not_equal', isLoading: false });
    }
  };

  // Handle cookie consent value update
  const handleCookieConsentReset = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    window.localStorage.removeItem('cookieConsentDeclined');
    resetCookieConsentValue();
    setAppState({ ...appState, successMessage: 'cookie_settings_updated' });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  // Handle user first name and last name change
  const updateUser = async () => {
    setAppState({ ...appState, isLoading: true });

    await axios
      .put(`${apiEndpoint}/api/account/details/`, {
        username: user.username,
        first_name: firstName,
        last_name: lastName,
      })
      .then((response) => {
        console.log(response.data);
        setAppState({ ...appState, isLoading: false, successMessage: 'user_updated' });
      })
      .catch((err) => {
        console.log(err.response);
        setAppState({ ...appState, errorMessage: 'unhandled_error', isLoading: false });
      });
  };

  return (
    <div className="page">
      <BackButton />
      <div className="page-title">
        <h1>{t('user.title')}</h1>
      </div>

      <div className="slices-container">
        <br />
        <h2>{t('user.user')}</h2>
        <div className="form-group rounded">
          <label className="form-label" htmlFor="email">
            {t('user.username')}
          </label>
          <input
            id="email"
            className="form-input"
            type="text"
            value={user.email ? user.email : ''}
            disabled
          />
        </div>
        <br />

        <form
          className="padding-bottom"
          onSubmit={(e) => {
            e.preventDefault();
            if (online === true) {
              updateUser();
            } else if (online === false) {
              setAppState({ ...appState, errorMessage: 'no_network_connection' });
            }
          }}
        >
          <div className="form-row">
            <div className="form-group rounded">
              <label className="form-label" htmlFor="fistname">
                {t('user.first_name')}
              </label>
              <input
                required
                id="fistname"
                className="form-input"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {firstName.length > 0 && (
                <span className="clear" onClick={() => setFirstName('')}>
                  <CloseIcon />
                </span>
              )}
            </div>
            <div className="form-group rounded">
              <label className="form-label" htmlFor="lastname">
                {t('user.last_name')}
              </label>
              <input
                required
                id="lastname"
                className="form-input"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {lastName.length > 0 && (
                <span className="clear" onClick={() => setLastName('')}>
                  <CloseIcon />
                </span>
              )}
            </div>
          </div>
          <div>
            <button
              className={`button default small ${online === false ? 'disabled' : ''}`}
              type="submit"
            >
              {t('app.save')}
            </button>
          </div>
        </form>

        <h2>{t('user.license')}</h2>

        {appState.isValidLicense ? (
          <p className="green">{`${t('user.license_message_1')} ${Intl.DateTimeFormat('fi').format(
            new Date(appState.expires)
          )} ${t('user.license_message_2')}`}</p>
        ) : (
          <p className="red">{t('user.no_valid_license')}</p>
        )}

        <button
          className={`button default small ${online === false ? 'disabled' : ''}`}
          onClick={() => {
            history.push('/activate');
          }}
        >
          {t('user.activate_new_license')}
        </button>

        <br />

        <h2>{t('user.change_password')}</h2>

        <form
          className="padding-bottom"
          onSubmit={(e) => {
            e.preventDefault();
            if (online === true) {
              verifyUserPassword();
            } else if (online === false) {
              setAppState({ ...appState, errorMessage: 'no_network_connection' });
            }
          }}
        >
          <div className="form-row">
            <div className="form-group rounded">
              <label className="form-label" htmlFor="pw">
                {t('user.new_password')}
              </label>
              <input
                required
                id="pw"
                className="form-input password"
                type={pwVisible ? 'text' : 'password'}
                value={pw}
                onChange={(e) => setPw(e.target.value)}
              />
              {pw.length > 0 && (
                <>
                  <span className="eye" onClick={() => setPwVisible(!pwVisible)}>
                    {pwVisible ? <EyeOffIcon /> : <EyeIcon />}
                  </span>
                  <span className="clear" onClick={() => setPw('')}>
                    <CloseIcon />
                  </span>
                </>
              )}
            </div>
            <div className="form-group rounded">
              <label className="form-label" htmlFor="pw-repeat">
                {t('user.repeat_password')}
              </label>
              <input
                required
                id="pw-repeat"
                className="form-input password"
                type={pwVisible ? 'text' : 'password'}
                value={pwRepeat}
                onChange={(e) => setPwRepeat(e.target.value)}
              />
              {pwRepeat.length > 0 && (
                <>
                  <span className="eye" onClick={() => setPwVisible(!pwVisible)}>
                    {pwVisible ? <EyeOffIcon /> : <EyeIcon />}
                  </span>
                  <span className="clear" onClick={() => setPwRepeat('')}>
                    <CloseIcon />
                  </span>
                </>
              )}
            </div>
          </div>
          <div>
            <button
              className={`button default small ${online === false ? 'disabled' : ''}`}
              type="submit"
            >
              {t('app.save')}
            </button>
          </div>
        </form>

        <h2>{t('user.cookies')}</h2>
        <p>
          {t('user.cookie_message')}{' '}
          <Link to="/privacy-policy">{t('cookie_consent.read_more')}</Link>
        </p>
        {cookies && (
          <button className="button default small" onClick={() => handleCookieConsentReset()}>
            {t('user.cookies_reset')}
          </button>
        )}
      </div>
    </div>
  );
};

export default User;
