import { useCallback, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import apiEndpoint from '../../utils/apiEndpoint';
import axios from 'axios';
import { AppStateContext } from '../contexts/AppStateContext';

const UserRefresher = (props) => {
  const { online } = props;
  const [user, setUser] = useContext(UserContext);
  const [{ isLoggedIn }] = useContext(AppStateContext);

  const fetchUser = useCallback(async () => {
    await axios
      .get(`${apiEndpoint}/api/account/details/`)
      .then((response) => {
        if (response.data) {
          setUser(response.data);
          window.localStorage.setItem('user', JSON.stringify(response.data));
        }
      })
      .catch((err) => {
        console.log('user fetch error: ', err.response);
      });
  }, [setUser]);

  useEffect(() => {
    const userFromStorage = window.localStorage.getItem('user');
    if (online && isLoggedIn && !user.username) {
      fetchUser();
    } else if (!user.username && userFromStorage && isLoggedIn) {
      setUser(JSON.parse(userFromStorage));
    }
  }, [isLoggedIn, fetchUser, online, user, setUser]);

  return null;
};

export default UserRefresher;
