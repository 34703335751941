import { useCallback, useContext, useEffect, useState } from 'react';
import apiEndpoint from '../../utils/apiEndpoint';
import axios from 'axios';
import { AppStateContext } from '../contexts/AppStateContext';

let intervalId;

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

// Tokens expire in 5 minutes, make check every 4 minutes
const refreshInterval = 4 * 60 * 1000;
const expiryInterval = 5 * 60 * 1000;

const localTokenWillExpire = () => {
  try {
    const { exp } = parseJwt(window.localStorage.getItem('token'));
    return exp && exp * 1000 + expiryInterval / 2 <= new Date().getTime();
  } catch (e) {
    return true;
  }
};

const TokenRefresher = (props) => {
  const { online } = props;
  const [appState, setAppState] = useContext(AppStateContext);
  const [needsRefresh, setNeedsRefresh] = useState(true);

  const getTokens = useCallback(
    async (token) => {
      await axios
        .post(`${apiEndpoint}/api/auth/refresh/`, { refresh: token })
        .then((response) => {
          const newToken = response.data?.refresh;
          const prismicToken = response.data?.prismic;
          if (newToken && prismicToken) {
            window.localStorage.setItem('refresh', newToken);
            window.localStorage.setItem('token', response.data?.access);
            // Force refresh of licence information on token refresh
            window.localStorage.removeItem('license');
            setAppState({
              ...appState,
              isLoading: false,
              isLoggedIn: true,
              prismicToken: prismicToken,
            });
          }
        })
        .catch((err) => {
          console.log('token get error: ', err.response);
        });
    },
    [appState, setAppState]
  );

  useEffect(() => {
    const token = window.localStorage.getItem('refresh');
    if (online && token && needsRefresh) {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
        intervalId = undefined;
      }

      setNeedsRefresh(false);
      // Always refresh tokens when the user refreshes the page to get the prismic token
      if ((appState.isLoggedIn !== undefined && !appState.isLoggedIn) || localTokenWillExpire()) {
        getTokens(token);
      }

      intervalId = setInterval(() => {
        console.log('toggle token validity check');
        setNeedsRefresh(true);
      }, refreshInterval);
    } else if (token && !online && appState.isLoggedIn === false) {
      setAppState((state) => {
        return {
          ...state,
          isLoggedIn: true,
        };
      });
    }
  }, [setAppState, appState.isLoggedIn, online, getTokens, needsRefresh, setNeedsRefresh]);

  return null;
};

export default TokenRefresher;
