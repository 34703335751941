import { useCallback, useContext, useEffect } from 'react';
import { AppStateContext } from '../contexts/AppStateContext';
import { LicensesContext } from '../contexts/LicensesContext';
import apiEndpoint from '../../utils/apiEndpoint';
import axios from 'axios';

const needsFetchLicense = (license) => {
  if (license) {
    const licenseAsDate = new Date(license);
    // eslint-disable-next-line
    if (licenseAsDate != 'Invalid Date') {
      return licenseAsDate <= new Date();
    }
    return license !== 'invalid';
  }
  return true;
};

const LicenseChecker = (props) => {
  const { online } = props;
  const [appState, setAppState] = useContext(AppStateContext);
  const [, setLicenses] = useContext(LicensesContext);
  const { isLoggedIn, expires, isLicenseActivated } = appState;

  const getLicenses = useCallback(async () => {
    await axios
      .get(`${apiEndpoint}/api/licenses/`)
      .then((response) => {
        if (response.data) {
          const latestLicense = new Date(
            Math.max(
              ...response.data
                .filter((licence) => !licence.is_blocked)
                .map((license) => new Date(license.expires))
            )
          );
          const isValidLicense = latestLicense >= new Date() ? true : false;
          window.localStorage.setItem(
            'license',
            isValidLicense ? String(latestLicense) : 'invalid'
          );
          setLicenses(response.data);
          if (String(latestLicense) !== expires) {
            setAppState((state) => {
              return {
                ...state,
                isValidLicense,
                isLoading: false,
                expires: String(latestLicense),
              };
            });
          } else {
            setAppState((state) => {
              return {
                ...state,
                isLoading: false,
              };
            });
          }
        } else {
          console.log('No licence data received');
          window.localStorage.setItem('license', 'invalid');
          setAppState((state) => {
            return {
              ...state,
              isValidLicence: false,
              isLoading: false,
              expires: new Date(),
            };
          });
        }
      })
      .catch((err) => {
        console.log('license error: ', err);
        window.localStorage.setItem('license', 'invalid');
        setAppState((state) => {
          return {
            ...state,
            isValidLicence: false,
            expires: new Date(),
          };
        });
      });
  }, [setLicenses, expires, setAppState]);

  useEffect(() => {
    const license = window.localStorage.getItem('license');

    if (online && isLoggedIn && needsFetchLicense(license)) {
      getLicenses();
    } else if (isLoggedIn && license && expires !== license && license !== 'invalid') {
      setAppState((state) => {
        return {
          ...state,
          isValidLicense: new Date(license) >= new Date() ? true : false,
          expires: license,
        };
      });
    }
  }, [isLoggedIn, expires, getLicenses, online, isLicenseActivated, setAppState]);

  return null;
};

export default LicenseChecker;
