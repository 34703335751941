import React, { useContext, useState, useEffect } from 'react';
import { ImagesContext } from '../contexts/ImagesContext';

const Image = (props) => {
  const { src, alt } = props;
  const [images] = useContext(ImagesContext);
  const [image, setImage] = useState({});

  useEffect(() => {
    if (src) {
      let source = src.toLowerCase();
      if (source?.indexOf('.png') > -1) {
        source = source.substring(0, source.indexOf('.png') + '.png'.length);
      } else if (source?.indexOf('.jpg') > -1) {
        source = source.substring(0, source.indexOf('.jpg') + '.jpg'.length);
      } else if (source?.indexOf('.jpeg') > -1) {
        source = source.substring(0, source.indexOf('.jpeg') + '.jpeg'.length);
      }

      const img = images.find((doc) => {
        return doc.id === source;
      });

      if (img) {
        setImage(img);
      }
    }
  }, [src, images]);

  return (
    image.doc?._attachments[image.id] ?
      <img
        className="serialized-image"
        src={`data:${image.doc?._attachments[image.id]?.content_type};base64,${
          image.doc?._attachments[image.id]?.data
        }`}
        alt={alt ? alt : ''}
      /> :
    <span />
  );
};

export default Image;
