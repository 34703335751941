import ReactGA from 'react-ga4';

export const analyticsInit = () => {
  const GA_ID =
    window.location.host.includes('localhost') || window.location.host.includes('staging')
      ? process.env.REACT_APP_GA_ID_STAGING
      : process.env.REACT_APP_GA_ID_PRODUCTION;

  ReactGA.initialize(GA_ID);

  ReactGA.gtag('consent', 'update', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
  });
};

export const defaultConsent = () => {
  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  });
};
