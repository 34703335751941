import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DocsProvider } from './components/contexts/DocsContext';
import { ImagesProvider } from './components/contexts/ImagesContext';
import { UserProvider } from './components/contexts/UserContext';
import i18next from 'i18next';
import maastotaulukotFi from './translations/fi/maastotaulukot.json';
import { I18nextProvider } from 'react-i18next';
import { LicensesProvider } from './components/contexts/LicensesContext';
import { Detector } from './react-detect-offline';
import axios from 'axios';
import { AppStateProvider } from './components/contexts/AppStateContext';
import { FavoritesProvider } from './components/contexts/FavoritesContext';
import { ServiceWorkerProvider } from './components/contexts/ServiceWorkerContext';

axios.defaults.withCredentials = true;

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'fi', // language to use
  resources: {
    fi: {
      maastotaulukot: maastotaulukotFi, // 'maastotaulukot' is our custom namespace
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerProvider>
      <I18nextProvider i18n={i18next}>
        <AppStateProvider>
          <DocsProvider>
            <ImagesProvider>
              <UserProvider>
                <LicensesProvider>
                  <FavoritesProvider>
                    <Detector render={({ online }) => <App online={online} />} />
                  </FavoritesProvider>
                </LicensesProvider>
              </UserProvider>
            </ImagesProvider>
          </DocsProvider>
        </AppStateProvider>
      </I18nextProvider>
    </ServiceWorkerProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
