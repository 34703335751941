import { useEffect, useContext } from 'react';
import qs from 'qs';
import { useClient } from '../utils/prismicConfig';
import { AppStateContext } from '../components/contexts/AppStateContext';

const linkResolver = (doc) => {
  if (doc.type === 'page') {
    return `/pages/${doc.id}`;
  } else if (doc.type === 'page_free') {
    return `/pages/${doc.id}`;
  } else if (doc.type === 'home') {
    return '/';
  } else if (doc.type === 'privacy_policy') {
    return '/privacy-policy';
  } else if (doc.type === 'about') {
    return '/about';
  }
  return '/';
};

const Preview = ({ history, location }) => {
  const [appState] = useContext(AppStateContext);
  const client = useClient(appState);

  useEffect(() => {
    const { token, documentId } = qs.parse(location.search.slice(1));
    if (!token) {
      console.log('No token available, check your configuration');
    }
    try {
      client
        ?.getPreviewResolver(token, documentId)
        .resolve(linkResolver, '/')
        .then((url) => history.push(url));
    } catch (e) {
      console.log(e);
    }
  }, [history, location, client]);

  return null;
};

export default Preview;
