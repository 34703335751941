import React, { useContext } from 'react';
import { DocsContext } from '../contexts/DocsContext';
import { RichText } from 'prismic-reactjs';
import { useTranslation } from 'react-i18next';

const IdToTitle = (props) => {
  const { id } = props;
  const { t } = useTranslation('maastotaulukot');
  const [docs] = useContext(DocsContext);
  const doc = docs?.find((doc) => {
    return doc.id === id;
  });

  return (
    <>
      {doc?.doc?.data?.title?.length > 0
        ? RichText.asText(doc?.doc?.data?.title)
        : t('app.not_found')}
    </>
  );
};

export default IdToTitle;
