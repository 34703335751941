import { useCallback, useContext, useEffect } from 'react';
import { AppStateContext } from '../contexts/AppStateContext';
import { FavoritesContext } from '../contexts/FavoritesContext';
import apiEndpoint from '../../utils/apiEndpoint';
import axios from 'axios';

const FavoritesChecker = (props) => {
  const { online } = props;
  const [{ isLoggedIn }] = useContext(AppStateContext);
  const [, setFavorites] = useContext(FavoritesContext);

  const getFavorites = useCallback(async () => {
    await axios
      .get(`${apiEndpoint}/api/favorites/`)
      .then((response) => {
        //console.log("favorites response: ", response.data)
        if (response.data) {
          window.localStorage.setItem('favorites', JSON.stringify(response.data));
          setFavorites(response.data);
        }
      })
      .catch((err) => {
        console.log('license error: ', err);
      });
  }, [setFavorites]);

  useEffect(() => {
    const favorites = window.localStorage.getItem('favorites');
    if (online && isLoggedIn) {
      getFavorites();
    } else if (isLoggedIn && favorites) {
      setFavorites(JSON.parse(favorites));
    }
  }, [isLoggedIn, getFavorites, online, setFavorites]);

  return null;
};

export default FavoritesChecker;
