import { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import Prismic from '@prismicio/client';
import { useClient } from '../../utils/prismicConfig';
import PouchDB from 'pouchdb';
import { DocsContext } from '../contexts/DocsContext';
import { ImagesContext } from '../contexts/ImagesContext';
import { AppStateContext } from '../contexts/AppStateContext';

const DocsChecker = (props) => {
  const { online } = props;
  const db = useMemo(() => new PouchDB('pages', { auto_compaction: true }), []);
  const imagesDB = useMemo(() => new PouchDB('images', { auto_compaction: true }), []);
  const [isDocsFetched, setIsDocsFetched] = useState(false);
  const [isDocsSync, setIsDocsSync] = useState(false);
  const [isImagesSync, setIsImagesSync] = useState(false);
  const [prismicDocs, setPrismicDocs] = useState([]);
  const [docs, setDocs] = useContext(DocsContext);
  const [, setImages] = useContext(ImagesContext);
  const [appState] = useContext(AppStateContext);
  const [totalResults, setTotalResults] = useState(null);
  const [docsToDelete, setDocsToDelete] = useState(null);
  const client = useClient(appState);
  const { isLoggedIn } = appState;

  const fetchImages = useCallback(
    (doc) => {
      const regex = /(http)?s?:?(\/\/[^"']*\.(?:png|PNG|jpe?g|JPE?G|png|PNG))/g;
      const imageURLs = JSON.stringify(doc).match(regex);

      imageURLs?.forEach((url) => {
        imagesDB
          .get(url.toLowerCase())
          .then((result) => {
            //console.log(result)
          })
          .catch(async (err) => {
            if (err.status === 404) {
              await fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                  const newImage = {
                    _id: url.toLowerCase(),
                    _attachments: {
                      [url.toLowerCase()]: {
                        content_type: blob.type,
                        data: blob,
                      },
                    },
                  };
                  imagesDB
                    .post(newImage)
                    .then((result) => {
                      console.log(result);
                      setIsImagesSync(true);
                      setTimeout(() => setIsImagesSync(false), 300);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
            }
          });
      });
    },
    [imagesDB]
  );

  const getDocumentsFromPrismic = useCallback(async () => {
    setIsDocsFetched(true);
    const response = await client?.query(
      Prismic.Predicates.any('document.type', [
        'home',
        'page',
        'page_free',
        'privacy_policy',
        'about',
      ]),
      { pageSize: 100 }
    ); // Max page size is 100

    if (response) {
      let results = response.results;
      const totalPages = response.total_pages;
      const totalResultsSize = response.total_results_size;
      let page = response.page;
      setTotalResults(totalResultsSize);

      for (let i = page; i < totalPages; i++) {
        let response = await client?.query(
          Prismic.Predicates.any('document.type', [
            'home',
            'page',
            'page_free',
            'privacy_policy',
            'about',
          ]),
          { pageSize: 100, page: page + 1 }
        );
        if (response) {
          results = results.concat(response.results);
          page = response.page;
        } else {
          break;
        }
      }

      const allDocs = results.map((doc) => {
        const newDoc = { ...doc, _id: doc.id };
        return newDoc;
      });

      if (allDocs.length === totalResultsSize) {
        const documents = [];
        allDocs.forEach((doc, i) => {
          db.get(doc._id)
            .then((result) => {
              const updatedDoc = { ...doc, _rev: result._rev };
              return db
                .put(updatedDoc)
                .then(() => {
                  documents.push(updatedDoc);
                  setIsDocsSync(true);
                  setTimeout(() => setIsDocsSync(false), 300);
                  fetchImages(updatedDoc);
                })
                .catch((err) => {
                  console.log('document update error: ', err);
                });
            })
            .catch((err) => {
              if (err.status === 404) {
                db.put(doc)
                  .then((result) => {
                    documents.push(doc);
                    setIsDocsSync(true);
                    setTimeout(() => setIsDocsSync(false), 300);
                    fetchImages(doc);
                  })
                  .catch((err) => console.log('new document save error: ', err));
              }
            });
        });
        setPrismicDocs(documents);
      }
    }
  }, [db, fetchImages, client]);

  // Get documents from prismic and sync with local database
  useEffect(() => {
    if (online && isLoggedIn && !isDocsFetched) {
      getDocumentsFromPrismic();
    }
  }, [db, online, isLoggedIn, isDocsFetched, getDocumentsFromPrismic]);

  // Get documents from database to context
  useEffect(() => {
    db.allDocs({
      include_docs: true,
    })
      .then((result) => {
        setDocs(result.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isDocsSync, db, setDocs]);

  // Set docs to state that are deleted or unpublished in Prismic
  useEffect(() => {
    if (prismicDocs.length === totalResults) {
      const toDelete = docs.filter((doc) => {
        const index = prismicDocs.findIndex((x) => x.id === doc.id);
        if (index > -1) {
          return null;
        } else {
          return doc;
        }
      });

      if (docsToDelete === null) {
        setDocsToDelete(toDelete);
      }
    }
  }, [docs, setDocs, prismicDocs, totalResults, docsToDelete]);

  // Delete docs from context and database
  useEffect(() => {
    if (docsToDelete?.length > 0) {
      docsToDelete.forEach((doc) => {
        db.get(doc.id)
          .then((doc) => {
            console.log('to delete: ', doc.id);
            return db.remove(doc);
          })
          .then((result) => {
            console.log('doc deleted: ', result);
            setDocs((docs) => {
              const newDocs = [...docs];
              const index = docs.findIndex((x) => x.id === doc.id);
              newDocs.splice(index, 1);
              return newDocs;
            });
          })
          .catch((err) => {
            console.log('error deleting doc: ', err);
          });
      });
    }
  }, [db, docsToDelete, setDocs]);

  // Get images from database to context
  useEffect(() => {
    imagesDB
      .allDocs({
        include_docs: true,
        attachments: true,
      })
      .then((result) => {
        setImages(result.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isImagesSync, setImages, imagesDB]);

  return null;
};

export default DocsChecker;
