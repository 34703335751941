import React from 'react';
import { ReactComponent as CloudIcon } from '../../assets/icons/icon-cloud-orange.svg';
import { useTranslation } from 'react-i18next';

const NoConnection = () => {
  const { t } = useTranslation('maastotaulukot');

  return (
    <div className="title-wrap no-margin">
      <CloudIcon />
      <h1>{t('app.no_network_connection_title')}</h1>
    </div>
  );
};

export default NoConnection;
