import React, { useState } from 'react';
import CounterInput from './CounterInput';
import CounterSlider from './CounterSlider';

const CrushedAshCounter = () => {
  const [crushedAshM3, setCrushedAshM3] = useState(0);

  const [ashEt, setAshEt] = useState(0);
  const [crushEt, setCrushEt] = useState(0);

  const [crushPercent, setCrushPercent] = useState(0);

  const [crushTm3, setCrushTm3] = useState(0);
  const [ashTm3, setAshTm3] = useState(0);

  return (
    <div className="slice counter">
      <CounterInput
        id="crushed-ash-m3"
        type="number"
        label="Tuhkamurske (m³)"
        min={0}
        plus={() => setCrushedAshM3((value) => parseFloat(value) + 1)}
        minus={() => setCrushedAshM3((value) => parseFloat(value) - 1)}
        value={crushedAshM3}
        placeholder="0"
        onChange={(e) => setCrushedAshM3(e.target.value)}
      />

      <div className="form-row">
        <CounterSlider
          defaultValue={0}
          label="Murske (%-osuus)"
          min={0}
          max={100}
          value={crushPercent}
          onChange={(e, newValue) => setCrushPercent(newValue)}
        />
        <CounterInput
          id="ash-percent"
          type="number"
          label="Tuhka (%-osuus)"
          value={100 - crushPercent}
          placeholder="0"
          disabled
        />
      </div>

      <div className="form-row result">
        <CounterInput
          id="crush-m3"
          type="number"
          label="Murske (m³)"
          value={(crushPercent / 100) * crushedAshM3}
          placeholder="0"
          result
          toFixed={2}
        />
        <CounterInput
          id="ash-m3"
          type="number"
          label="Tuhka (m³)"
          value={((100 - crushPercent) / 100) * crushedAshM3}
          placeholder="0"
          result
          toFixed={2}
        />
      </div>

      <div className="form-row">
        <CounterInput
          id="crush-et"
          type="number"
          label="Murske (€/t)"
          min={0}
          plus={() => setCrushEt((value) => parseFloat(value) + 1)}
          minus={() => setCrushEt((value) => parseFloat(value) - 1)}
          value={crushEt}
          placeholder="0"
          onChange={(e) => setCrushEt(e.target.value)}
        />
        <CounterInput
          id="ash-et"
          type="number"
          label="Tuhka (€/t)"
          min={0}
          plus={() => setAshEt((value) => parseFloat(value) + 1)}
          minus={() => setAshEt((value) => parseFloat(value) - 1)}
          value={ashEt}
          placeholder="0"
          onChange={(e) => setAshEt(e.target.value)}
        />
      </div>

      <div className="form-row">
        <CounterInput
          id="crush-tm3"
          type="number"
          label="Murske (t/m³ tai kg/dm³)"
          min={0}
          plus={() => setCrushTm3((value) => parseFloat(value) + 1)}
          minus={() => setCrushTm3((value) => parseFloat(value) - 1)}
          value={crushTm3}
          placeholder="0"
          onChange={(e) => setCrushTm3(e.target.value)}
        />
        <CounterInput
          id="ash-tm3"
          type="number"
          label="Tuhka (t/m³ tai kg/dm³)"
          min={0}
          plus={() => setAshTm3((value) => parseFloat(value) + 1)}
          minus={() => setAshTm3((value) => parseFloat(value) - 1)}
          value={ashTm3}
          placeholder="0"
          onChange={(e) => setAshTm3(e.target.value)}
        />
      </div>

      <div className="form-row result">
        <CounterInput
          id="crushed-ash-tm3"
          type="number"
          label="Tuhkamurske (t/m³ tai kg/dm³)"
          value={(crushPercent / 100) * crushTm3 + ((100 - crushPercent) / 100) * ashTm3}
          placeholder="0"
          result
          toFixed={2}
        />
      </div>

      <div className="form-row result">
        <CounterInput
          id="crush-t"
          type="number"
          label="Murske (t)"
          value={
            ((crushPercent / 100) * crushTm3 + ((100 - crushPercent) / 100) * ashTm3) *
            crushedAshM3 *
            (crushPercent / 100)
          }
          placeholder="0"
          result
          toFixed={2}
        />
        <CounterInput
          id="ash-t"
          type="number"
          label="Tuhka (t)"
          value={
            ((crushPercent / 100) * crushTm3 + ((100 - crushPercent) / 100) * ashTm3) *
            crushedAshM3 *
            ((100 - crushPercent) / 100)
          }
          placeholder="0"
          result
          toFixed={2}
        />
        <CounterInput
          id="crushed-ash-t"
          type="number"
          label="Tuhkamurske (t)"
          value={
            ((crushPercent / 100) * crushTm3 + ((100 - crushPercent) / 100) * ashTm3) * crushedAshM3
          }
          placeholder="0"
          result
          toFixed={2}
        />
      </div>

      <div className="form-row result">
        <CounterInput
          id="crush-e"
          type="number"
          label="Murske (€)"
          value={(crushPercent / 100) * crushedAshM3 * crushEt}
          placeholder="0"
          result
          toFixed={2}
        />
        <CounterInput
          id="ash-e"
          type="number"
          label="Tuhka (€)"
          value={((100 - crushPercent) / 100) * crushedAshM3 * ashEt}
          placeholder="0"
          result
          toFixed={2}
        />
        <CounterInput
          id="crushed-e"
          type="number"
          label="Tuhkamurske (€)"
          value={
            (crushPercent / 100) * crushedAshM3 * crushEt +
            ((100 - crushPercent) / 100) * crushedAshM3 * ashEt
          }
          placeholder="0"
          result
          toFixed={2}
        />
      </div>
    </div>
  );
};

export default CrushedAshCounter;
