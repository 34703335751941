import React, { useContext, useEffect } from 'react';
import BackButton from '../components/navigation/BackButton';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Divider, IconButton } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { ReactComponent as ChevronRight } from '../assets/icons/icon-chevron-right.svg';
import { useTranslation } from 'react-i18next';
import { FavoritesContext } from '../components/contexts/FavoritesContext';
import apiEndpoint from '../utils/apiEndpoint';
import axios from 'axios';
import { AppStateContext } from '../components/contexts/AppStateContext';
import Breadcrumbs from '../components/navigation/Breadcrumbs';
import IdToTitle from '../components/utils/IdToTitle';

const Favorites = (props) => {
  const { online } = props;
  const { t } = useTranslation('maastotaulukot');
  const [favorites, setFavorites] = useContext(FavoritesContext);
  const [appState, setAppState] = useContext(AppStateContext);

  const deleteFavorite = async (id) => {
    await axios
      .delete(`${apiEndpoint}/api/favorites/${id}/`)
      .then(async (response) => {
        console.log('favorites delete response: ', response);
        if (response.status === 204) {
          setAppState({ ...appState, successMessage: 'deleted_from_favorites' });
          await axios
            .get(`${apiEndpoint}/api/favorites/`)
            .then((response) => {
              console.log('favorites refresh response: ', response.data);
              if (response.data) {
                setFavorites(response.data);
                window.localStorage.setItem('favorites', JSON.stringify(response.data));
              }
            })
            .catch((err) => {
              setAppState({ ...appState, errorMessage: 'unhandled_error' });
              console.log('favorites refresh error: ', err);
            });
        }
      })
      .catch((err) => {
        setAppState({ ...appState, errorMessage: 'unhandled_error' });
        console.log('favorites delete error: ', err.response);
      });
  };

  useEffect(() => {
    document.title = `${t('favorites.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  return (
    <div className="page">
      <BackButton />
      <div className="page-title">
        <h1>{t('favorites.title')}</h1>
      </div>
      <div className="slices-container">
        {favorites?.length > 0 ? (
          <List className="favorites-slice slice">
            {favorites?.map((favorite) => {
              return (
                <div className="wrap" key={favorite.id}>
                  <Link to={`/pages/${favorite.key}`}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <div className="item-wrap">
                            <div className="title-wrap truncate-wrap">
                              <p>
                                <Breadcrumbs id={favorite.key} />
                              </p>
                              <h4 className="truncate">
                                <IdToTitle id={favorite.key} />
                              </h4>
                            </div>
                            <ChevronRight style={{ marginLeft: 16, flexShrink: 0 }} />
                          </div>
                        }
                      />
                    </ListItem>
                  </Link>
                  <div className="delete">
                    <IconButton
                      className={online === true ? '' : 'disabled'}
                      onClick={() => {
                        if (online === true) {
                          deleteFavorite(favorite.id);
                        } else {
                          setAppState({ ...appState, errorMessage: 'no_network_connection' });
                        }
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </div>
                  <Divider />
                </div>
              );
            })}
          </List>
        ) : (
          <p className="favorites-notice">{t('favorites.no_favorites_message')}</p>
        )}
      </div>
    </div>
  );
};

export default Favorites;
