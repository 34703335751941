import React, { useState, useContext, useEffect } from 'react';
import { Paper, IconButton } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close.svg';
import { ReactComponent as CloseIconWhite } from '../assets/icons/icon-close-white.svg';
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg';
import { ReactComponent as EyeOffIcon } from '../assets/icons/icon-eye-off.svg';
import logo from '../assets/logos/logo-1.svg';
import logoMetsa from '../assets/logos/logo-metsanhoidon.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import apiEndpoint from '../utils/apiEndpoint';
import axios from 'axios';
import { AppStateContext } from '../components/contexts/AppStateContext';
import NoConnection from '../components/utils/NoConnection';
import packageJson from '../../package.json';

const LogIn = (props) => {
  const { online } = props;
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const [pwVisible, setPwVisible] = useState(false);
  const { t } = useTranslation('maastotaulukot');
  const [, setAppState] = useContext(AppStateContext);
  const [toast, setToast] = useState(false);

  useEffect(() => {
    const showToast = window.localStorage.getItem('loginToast');
    if (showToast === 'closed') {
      setToast(false);
    } else {
      setToast(true);
    }
  }, []);

  useEffect(() => {
    document.title = `${t('login.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  const loginUser = async () => {
    await axios
      .post(`${apiEndpoint}/api/auth/login/`, { username: email, password: pw })
      .then((response) => {
        console.log(response);
        if (response.data?.Success && response.status === 200) {
          setAppState((state) => {
            return {
              ...state,
              isLoggedIn: true,
              prismicToken: response.data?.data?.prismic,
            };
          });
          window.localStorage.setItem('refresh', response.data?.data?.refresh);
          window.localStorage.setItem('token', response.data?.data?.access);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response?.data?.Invalid) {
          setAppState((state) => {
            return {
              ...state,
              errorMessage: 'invalid_username_or_password',
            };
          });
        } else {
          setAppState((state) => {
            return { ...state, errorMessage: 'unhandled_error' };
          });
        }
      });
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <Paper className="login-box">
          {online !== false ? (
            <>
              <div className="logo-wrap">
                <img src={logo} alt="Maastotaulukot Logo" />
              </div>
              <div className="title-wrap">
                <h1>{t('login.title')}</h1>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  loginUser();
                }}
              >
                <div className="form-group">
                  <input
                    required
                    className="form-input"
                    type="email"
                    placeholder={t('app.placeholder_username')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {email.length > 0 && (
                    <span
                      className="clear"
                      onClick={() => {
                        setEmail('');
                      }}
                    >
                      <CloseIcon />
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    required
                    className="form-input password"
                    type={pwVisible ? 'text' : 'password'}
                    placeholder={t('app.placeholder_password')}
                    value={pw}
                    onChange={(e) => setPw(e.target.value)}
                  />
                  {pw.length > 0 && (
                    <>
                      <span className="eye" onClick={() => setPwVisible(!pwVisible)}>
                        {pwVisible ? <EyeOffIcon /> : <EyeIcon />}
                      </span>
                      <span className="button clear" onClick={() => setPw('')}>
                        <CloseIcon />
                      </span>
                    </>
                  )}
                </div>
                <Link to="/ask-reset" className="button link">
                  {t('login.forgot_password')}
                </Link>
                <button className="button default full-width" type="submit">
                  {t('login.login')}
                </button>
                <div className="register-text">
                  <p> {t('login.register_message')}</p>
                  <Link to="/register" className="button link">
                    {t('register.title')}
                  </Link>
                </div>
                <div className="logo-wrap-bottom">
                  <img src={logoMetsa} alt="Metsänhoidon suositukset logo" />
                </div>
              </form>
            </>
          ) : (
            <NoConnection />
          )}
          <div className={`toast login-toast ${toast ? 'is-active' : ''}`}>
            <div className="wrap orange">
              <div className="content">
                <p>
                  {t('toast.login_message')} {t('toast.login_message_more_info')}{' '}
                  <a
                    href="https://www.metsakauppa.fi/tuote/maastotaulukot-mobiili/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('toast.login_message_webstore')}
                  </a>
                </p>
              </div>
              <div className="close">
                <span>
                  <IconButton
                    onClick={() => {
                      window.localStorage.setItem('loginToast', 'closed');
                      setToast(false);
                    }}
                  >
                    <CloseIconWhite />
                  </IconButton>
                </span>
              </div>
            </div>
          </div>
        </Paper>
        <p className="version-number">
          {t('app.version')} {packageJson.version}
        </p>
      </div>
    </div>
  );
};

export default LogIn;
