import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = (props) => {
  const { history } = props;
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default withRouter(ScrollToTop);
